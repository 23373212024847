import React, { Component } from 'react';

class Sidebara extends Component{
    render(){

        let data = [
            {sidebarListLink: "service-details-left-sidebar", sidebarListIcon: 'flaticon-015-cart', sidebarListText: '2 plantas'},
            {sidebarListLink: "service-details-left-sidebar", sidebarListIcon: 'flaticon-010-tank-1', sidebarListText: '3 habitaciones'},
            {sidebarListLink: "service-details-left-sidebar", sidebarListIcon: 'flaticon-002-welding', sidebarListText: '2 baños completos'},
            {sidebarListLink: "service-details-left-sidebar", sidebarListIcon: 'flaticon-004-walkie-talkie', sidebarListText: 'Terreno de 105 m2'},
            {sidebarListLink: "service-details-left-sidebar", sidebarListIcon: 'flaticon-042-monitor', sidebarListText: 'Estacionamiento para 2 autos'},
            {sidebarListLink: "service-details-left-sidebar", sidebarListIcon: 'flaticon-050-boxes', sidebarListText: 'Amenidades'}
        ];

        let Datalist = data.map((val, i)=>{
            return(
                <li key={i}><a href={val.sidebarListLink}><i className={val.sidebarListIcon} />{val.sidebarListText}</a></li>
            )
        })

        return(
            <div>
                <div className="sidebar-wrapper">
                    <div className="sidebar">
                        <h3 className="sidebar-title">Especificaciones exteriores</h3>
                        <ul className="sidebar-list">
                            {Datalist}
                        </ul>
                    </div>
                    <div className="sidebar">
                        <h3 className="sidebar-title">Especificaciones Interiores</h3>
                        <ul className="sidebar-list">
                            <li><a href="/"><i className="fa fa-file-pdf-o" />Habitación en baja</a></li>
                            <li><a href="/"><i className="fa fa-file-word-o" />2 habitaciones en planta alta con vestidor </a></li>
                            <li><a href="/"><i className="fa fa-file-word-o" />Terraza</a></li>
                            <li><a href="/"><i className="fa fa-file-word-o" />Loseta de cerámica </a></li>
                            <li><a href="/"><i className="fa fa-file-word-o" />Impermeabilizante </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebara;