import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Sidebar from "./components/Sidebara";
import ServiceGallery from "./components/ServiceGallery2";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class ServiceDetailsArboledas extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/portadacasas.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Casa Arboledas</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Inicio</a>
                    </li>
                    <li>
                      <a href={`${process.env.PUBLIC_URL}/services`}>
                        Casas
                      </a>
                    </li>
                    <li>Arboledas</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--120">
          {/*Service section start*/}
          <div className="service-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12 order-1 order-lg-2">
                  <div className="service-details">
                    {/* service gallery */}
                    <ServiceGallery />

                    <div className="content section-space--top--30">
                      <div className="row">
                        <div className="col-12">
                          <h2>Arboledas</h2>
                        </div>
                        <div className="col-lg-6 col-12 section-space--top--30">
                          <h3>Amplitud y Diseño</h3>
                          <p>
                          Arboledas brinda un espacio generoso con 114 m² de construcción, distribuidos en una estructura de dos plantas con diseño moderno y funcional.
                          </p>
                        </div>
                        <div className="col-lg-6 col-12 section-space--top--30">
                          <h3>Confort Familiar</h3>
                          <p>
                          Este modelo es ideal para familias, ofreciendo tres habitaciones amplias que aseguran privacidad y comodidad para todos.
                          </p>
                        </div>
                        <div className="col-lg-6 col-12 section-space--top--30">
                          <h3>Capacidad de Estacionamiento</h3>
                          <p>
                          Benefíciate de un amplio estacionamiento para dos autos, proporcionando comodidad y seguridad para tus vehículos.
                          </p>
                        </div>
                        <div className="col-lg-6 col-12 section-space--top--30">
                          <h3>Doble Sanitario Completo</h3>
                          <p>
                          Equipada con dos baños completos, Arboledas facilita la rutina diaria, evitando esperas y añadiendo un extra de confort a tu vida cotidiana.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 order-2 order-lg-1">
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default ServiceDetailsArboledas;
